export default {
    EMPTY: {
        anchor: 'center',
        className: 'map-icon empty',
    },
    USER: {
        anchor: 'bottom',
        className: 'map-icon user',
    },
    USER_UNLABELED: {
        anchor: 'bottom',
        className: 'map-icon user unlabeled',
    },
    PLACE: {
        anchor: 'bottom',
        className: 'map-icon place',
    },
    PLACE_HOVER: {
        anchor: 'bottom',
        className: 'map-icon place hover',
    },
    PLACE_SPONSORED: {
        anchor: 'bottom',
        className: 'map-icon place sponsored',
    },
    PLACE_HOME: {
        anchor: 'bottom',
        className: 'map-icon place home',
    },
    PLACE_UNLABELED: {
        anchor: 'bottom',
        className: 'map-icon place unlabeled',
    },
    DOT: {
        anchor: 'center',
        className: 'map-icon dot',
    },
    DOT_UNLABELED: {
        anchor: 'center',
        className: 'map-icon dot unlabeled',
    },
}
