export const guid = () => {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)
    }
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

export const uglifyName = (name) => {
    let names = name.split(' ')
    let eventName = ''

    names.forEach(function (part) {
        let newPart = part.replace(/^([a-z])/, function (txt) {
            return txt.toUpperCase()
        })
        newPart = newPart.replace(/[^A-Za-z0-9]/, '')
        eventName += newPart
    })

    return eventName
}

export const normalizeCharacters = (str) => {
    str = str.replace(/(å|ä|á|à|ã|â)/gi, 'a')
    str = str.replace(/(ö|ó|ò|õ|ô|ø)/gi, 'o')
    str = str.replace(/(ê|ë|é|è)/gi, 'e')
    str = str.replace(/(ü|û|ú|ù)/gi, 'u')
    str = str.replace(/(ï|î|í|ì)/gi, 'i')
    str = str.replace(/(ý|ÿ)/gi, 'y')
    str = str.replace('ñ', 'n')
    str = str.replace('ç', 'c')
    str = str.replace('æ', 'ae')
    str = str.replace('ß', 'ss')
    str = str.replace('Þ', 'th')
    str = str.replace('µ', 'my')

    return str
}

export const urlSafe = (str, trim) => {
    if (trim !== false) trim = true
    str = trim ? str.trim() : str
    str = str.toLowerCase()
    str = normalizeCharacters(str)
    str = str.replace(' ', '-')
    str = str.replace(/([^a-z0-9-])/g, '-')
    str = str.replace(/([-]{2,})/g, '-')
    str = trim ? str.replace(/(^-|-$)/, '') : str
    str = str.substr(0, 45)
    if (str.length == 0) str = '---'
    return str
}

export const stripToNull = (str) => {
    if (str == null) return null

    return str.trim(str)
}
