import '../../../../../styles/less/modules/map-markers.less'
import '../../../../../styles/less/modules/map.less'
import markers from './markers.js'
import { guid } from '../../utils/string.js'
import { isTouchDevice } from '../../utils/touch.js'
import { googleMaps } from './googleMaps.js'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

const activeMaps = []

export const setMapScrolling = function (draggable) {
    activeMaps.forEach(function (map) {
        if (map.map) {
            map.map.setOptions({
                draggable: draggable,
                scrollwheel: draggable,
            })
        }
    })
}

const MAPBOX_API_KEY =
    'pk.eyJ1IjoidGhhdHN1cCIsImEiOiJjbDZ4amxlMjEwdG5tM2VxbDFxZWl3ejRtIn0.N_vNChIoWuobpc-74McPxA'
const MAPBOX_STYLE_STATIC = 'thatsup/cjm0ckc8s0c5x2rrynr7u636v'
const MAPBOX_STYLE_INTERACTIVE = 'thatsup/cjm0ckc8s0c5x2rrynr7u636v'

export const staticMapUrl = function (options) {
    const width = options.width || 500
    const height = options.height || 300

    const styleKey = MAPBOX_STYLE_STATIC
    const accessToken = MAPBOX_API_KEY
    const baseUrl = `https://api.mapbox.com/styles/v1/${styleKey}/static`

    const hasMultipleMarkers = options.locations.length > 1

    let bounds = []

    if (hasMultipleMarkers) {
        const markerBounds = options.locations.reduce(function (acc, location) {
            const markerBounds = new mapboxgl.LngLat(location.lng, location.lat).toBounds(100)
            return acc === null ? markerBounds : acc.extend(markerBounds)
        }, null)
        const nw = markerBounds.getNorthWest()
        const se = markerBounds.getSouthEast()
        bounds = [nw.lng - 0.0005, se.lat + 0.0005, se.lng + 0.0005, nw.lat - 0.0005]
    }

    const retinaSuffix = window.devicePixelRatio > 1 ? '@2x' : ''
    const size = `${width}x${height}${retinaSuffix}` // <-- Not working as expected
    const zoom = options.zoom || 15
    const center = hasMultipleMarkers
        ? `[${bounds.join(',')}]`
        : `${options.locations[0].lng},${options.locations[0].lat},${zoom}`

    const markerUrl = encodeURIComponent(
        `https://static.thatsup.co/media/img/icon/map/static${retinaSuffix}.png?v=1656945573631`,
    )

    const markers = options.locations
        .map((location) => {
            return `url-${markerUrl}(${location.lng},${location.lat})`
        })
        .join(',')

    let url = `${baseUrl}/${markers}/${center}/${size}?access_token=${accessToken}`

    if (hasMultipleMarkers) {
        url += '&padding=50,20,10,20'
    }

    return url
}

export class interactiveMap {
    constructor(options) {
        if (!(this instanceof interactiveMap)) {
            return new interactiveMap(this._options)
        }

        // This is not a pretty solution, but it is necessary since map.js is used in both Thatsup frontend and Admin
        // @TODO: Find a better solution of fetching the current city
        const city = window.globalVars._city || null

        if (city) {
            this.center =
                city.longitude && city.latitude
                    ? [city.longitude, city.latitude]
                    : [18.05214, 59.34015]
        } else {
            this.center = [18.05214, 59.34015]
        }

        this.verbose = false

        this.markers = []
        this.overlays = []
        this.overlayIndex = 0

        this._options = options
        this.loadQueue = []
        this.loaded = false
        this.activeMarker = null
        this.isTouching = false
        this.clusterBuster = 100000
        this.latLngCache = []
        this.mapboxgl = mapboxgl

        let container = null

        if (typeof this.mapboxgl === 'undefined') {
            console.error('Mapbox GL was not found')
            return false
        }

        if (typeof this._options.container === 'string') {
            container = document.querySelector(this._options.container)
        } else if ('jQuery' in window && this._options.container instanceof window.jQuery) {
            // Element is jQuery
            container = this._options.container.get(0)
        } else {
            container = this._options.container
        }

        if (container.length === 0) {
            return false
        }

        if (this._options.height != null) {
            container.style.height = this._options.height + 'px'
        }
        if (this._options.width != null) {
            container.style.width = this._options.width + 'px'
        }

        this.mapboxgl.accessToken = MAPBOX_API_KEY

        this.map = new this.mapboxgl.Map({
            container: container,
            style: 'mapbox://styles/' + MAPBOX_STYLE_INTERACTIVE,
            zoom: this._options.zoom != null ? this._options.zoom : 16,
            center: this.center,
        })

        if (this._options.keyboard === false) {
            this.map.keyboard.disable()
        }

        if (!('ontouchstart' in window)) {
            this.map.addControl(new this.mapboxgl.NavigationControl(), 'bottom-right')
        }

        if (this._options.padding) {
            this.map.setPadding(this._options.padding)
            // this.map.showPadding = true;
        }

        this.map.on('load', () => {
            this.load.apply(this, [])
        })

        const canvas = this.map.getCanvas()
        canvas.addEventListener('click', (e) => {
            if (e.target === canvas) {
                this.unsetActiveMarkers()
            }
        })
        if (this._options.click) {
            canvas.addEventListener('click', (e) => {
                if (e.target === canvas) {
                    this._options.click.apply(this, [])
                }
            })
        }
        if (this._options.events) {
            for (const evt in this._options.events) {
                this.map.on(evt, this._options.events[evt].bind(this))
            }
        }

        this.map.on('touchstart', () => {
            this.isTouching = true
        })
        this.map.on('touchend', () => {
            this.isTouching = false
        })

        const shouldAutoZoom = !(
            this._options.autoZoom === false ||
            this._options.zoom ||
            this._options.center
        )

        if (this._options.polygons && this._options.polygons.length) {
            this._options.polygons.forEach((polygon) => {
                this.addPolygon(polygon)
            })
        }

        this.renderMarkers(this._options.markers, shouldAutoZoom)

        if (this._options.center) {
            this.setCenter(this._options.center)
        }
        if (this._options.zoom != null) {
            this.map.setZoom(this._options.zoom)
        }
        if (this._options.pitch != null) {
            this.map.setPitch(this._options.pitch)
        }
        if (this._options.bearing != null) {
            this.map.setBearing(this._options.bearing)
        }

        this.controls = {}

        this._options.controlPosition =
            typeof this._options.controlPosition !== 'undefined'
                ? this._options.controlPosition
                : 'bottom-right'
        this._options.navigationControl =
            typeof this._options.navigationControl !== 'undefined'
                ? this._options.navigationControl
                : isTouchDevice()
        if (this._options.navigationControl === true) {
            this.addControl(
                'navigationControl',
                new this.mapboxgl.NavigationControl({
                    showCompass: false,
                    showZoom: true,
                }),
                this._options.controlPosition,
            )
        } else if (this._options.navigationControl !== false) {
            this.addControl(
                'navigationControl',
                this._options.navigationControl,
                this._options.controlPosition,
            )
        }
        if (this._options.geoControl === true) {
            this.addControl(
                'geoControl',
                new this.mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true,
                    },
                    trackUserLocation: true,
                }),
                this._options.controlPosition,
            )
        }
        if ('controls' in this._options) {
            this._options.controls.forEach((c) => {
                this.addControl(c.key, c.control, c.position || this._options.controlPosition)
            })
        }
    }

    removeControl(key) {
        const control = this.controls[key]
        if (control) {
            this.map.removeControl(control.control)
            delete this.controls[key]
        }
    }

    removeLayer(id) {
        return this.removeShapes(function (s) {
            return s.id === id
        })
    }

    getZoom() {
        return this.map.getZoom()
    }

    getCenter() {
        return this.map.getCenter()
    }

    log(...args) {
        if (this.verbose) {
            console.log(...args)
        }
    }

    setZoom(zoom) {
        return this.map.setZoom(zoom)
    }

    addControl(key, control, position) {
        if (typeof key === 'object') {
            position = control
            control = key
            key = control.key
        }
        position = position || this._options.controlPosition
        this.map.addControl(control, position)
        const mapControl = {
            key: key,
            control: control,
            position: position,
            visible: true,
        }
        this.controls[key] = mapControl
        return mapControl
    }

    getCirclePoints(lat, lng, radius) {
        // math lifted from maps.forum.nu.  you want map examples, go there.
        const points = []
        const d = radius / 6378137 // accuracy / meters of Earth radius = radians
        const lat1 = (Math.PI / 180) * lat // radians
        const lng1 = (Math.PI / 180) * lng // radians

        for (let a = 0; a < 361; a += 8) {
            const tc = (Math.PI / 180) * a
            const y = Math.asin(
                Math.sin(lat1) * Math.cos(d) + Math.cos(lat1) * Math.sin(d) * Math.cos(tc),
            )
            const dlng = Math.atan2(
                Math.sin(tc) * Math.sin(d) * Math.cos(lat1),
                Math.cos(d) - Math.sin(lat1) * Math.sin(y),
            )
            const x = ((lng1 - dlng + Math.PI) % (2 * Math.PI)) - Math.PI // MOD function
            const point = [parseFloat(x * (180 / Math.PI)), parseFloat(y * (180 / Math.PI))]
            points.push(point)
        }
        return points
    }

    latLngKey(lat, lng) {
        return (
            Math.round(parseFloat(lat) * this.clusterBuster) / this.clusterBuster +
            ',' +
            Math.round(parseFloat(lng) * this.clusterBuster) / this.clusterBuster
        )
    }

    async getCirclePaths(lat, lng, radius) {
        const points = this.getCirclePoints(lat, lng, radius)
        const paths = []
        const maps = await googleMaps()
        for (let i = 0; i < points.length; i++) {
            const point = new maps.LatLng(points[i][0], points[i][1])
            paths.push(point)
        }
        return paths
    }

    isLoaded() {
        return this.loaded
    }

    getBounds() {
        return this.map.getBounds()
    }

    removeMarkers(condition) {
        for (let i = this.markers.length - 1; i >= 0; i--) {
            if (condition) {
                const conditionResult = condition.apply(this, [this.markers[i], 'marker'])
                if (conditionResult === false) {
                    continue
                }
            }
            this.markers[i].remove()
            this.markers.splice(i, 1)
        }
    }

    addPolygon(polygon, layerOptions, featureOptions) {
        if (!this.isLoaded()) {
            this.queue(function () {
                this.addPolygon(polygon, layerOptions, featureOptions)
            })
            return
        }
        let layer = {
            type: 'fill',
            layout: {},
            paint: {
                'fill-color': 'rgba(252,157,78,0.15)',
                'fill-outline-color': 'rgba(252,157,78,1)',
            },
        }
        layer = { ...layer, ...(layerOptions || {}) }
        //$.extend(layer, layerOptions || {})

        const source = {
            type: 'geojson',
            data: this.getPolygonFeature(polygon, featureOptions),
        }
        return this.addLayer(layer, source)
    }

    getShapes() {
        return this.overlays
    }

    load() {
        this.log('Map loaded.')
        this.loaded = true
        if (this._options.load) {
            this._options.load.apply(this, [])
        }
        this.runQueue()
    }

    getMarkerById(id) {
        for (let i = 0; i < this.markers.length; i++) {
            if (this.markers[i].id === id) {
                return this.markers[i]
            }
        }
        return null
    }

    async getAddress(lat, lng, callback) {
        if (arguments.length < 3) {
            if (Array.isArray(lat)) {
                callback = lng
                lng = lat[1]
                lat = lat[0]
            }
        }
        const maps = await googleMaps()
        const geocoder = new maps.Geocoder()
        geocoder.geocode(
            {
                location: { lat: lat, lng: lng },
            },
            callback,
        )
    }

    getPolygonFeature(polygon, options) {
        const coordinates = polygon.map(function (points) {
            return [parseFloat(points[1]), parseFloat(points[0])]
        })
        return {
            id: 'feature_' + guid(),
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
            },
            ...(options || {}),
        }
    }

    renderMarker(markerOptions) {
        // create a DOM element for the marker
        const el = document.createElement('div')

        markerOptions.icon =
            markerOptions.icon || (markerOptions.label ? markers.PLACE : markers.PLACE_UNLABELED)

        el.className = markerOptions.icon.className || ''

        if (markerOptions.label) {
            el.innerText = markerOptions.label
        } else {
            el.classList.add('unlabeled')
        }

        if (markerOptions.quicklook) {
            el.classList.add('thhook')
            el.dataset.action = 'quicklook'
            el.dataset.type = markerOptions.quicklook.type
            el.dataset.id = markerOptions.quicklook.id
        }

        markerOptions.draggable = !!markerOptions.draggable

        if (markerOptions.draggable) {
            el.classList.add('draggable')
            el.addEventListener('touchstart', function (e) {
                e.preventDefault()
            })
        }

        // el.addEventListener('click', function() {
        // 	window.alert(marker.properties.message);
        // });
        const latLngKey = this.latLngKey(markerOptions.lat, markerOptions.lng)
        const existingAtPoint = this.latLngCache[latLngKey]
        if (existingAtPoint) {
            const latOffset = (1 / this.clusterBuster) * existingAtPoint
            const lngOffset = (1 / this.clusterBuster) * existingAtPoint
            markerOptions.lat += latOffset
            markerOptions.lng += lngOffset
            this.latLngCache[latLngKey]++
        } else {
            this.latLngCache[latLngKey] = 1
        }
        // add marker to map
        const m = new this.mapboxgl.Marker({
            element: el,
            anchor: markerOptions.icon.anchor || 'bottom',
            draggable: markerOptions.draggable,
        })
        m.setLngLat([markerOptions.lng, markerOptions.lat])
        m.addTo(this.map)
        m.data = markerOptions.data
        m.map = this
        m.id = markerOptions.id

        if (markerOptions.drag) {
            m.on('drag', markerOptions.drag)
        }
        if (markerOptions.dragstart) {
            m.on('dragstart', markerOptions.dragstart)
        }
        if (markerOptions.dragend) {
            m.on('dragend', markerOptions.dragend)
        }

        if (markerOptions.popup) {
            let popup
            if (!(markerOptions.popup instanceof this.mapboxgl.Popup)) {
                popup = new this.mapboxgl.Popup({
                    offset: markerOptions.popup.offset || m,
                    anchor: markerOptions.popup.anchor || markerOptions.icon.anchor,
                    closeButton: markerOptions.popup.closeButton,
                    closeOnClick: markerOptions.popup.closeOnClick,
                    className:
                        markerOptions.popup.className ||
                        'popup__' + (markerOptions.icon.className || ''),
                })
                if (markerOptions.popup.html) {
                    popup.setHTML(markerOptions.popup.html)
                }
                if (markerOptions.popup.text) {
                    popup.setHTML(markerOptions.popup.text)
                }
            } else {
                popup = markerOptions.popup
            }
            if (popup) {
                m.setPopup(popup)
            }
        }

        m.isActive = false
        m.setActive = function (active) {
            if (active === this.isActive) {
                return
            }
            if (active) {
                el.classList.add('active')
                this.isActive = true
            } else {
                el.classList.remove('active')
                this.isActive = false
                const popup = this.getPopup()
                if (popup && popup.isOpen()) {
                    this.togglePopup()
                }
            }
        }
        m.setPosition = function (lat, lng) {
            if (Array.isArray(lat)) {
                lng = lat[1]
                lat = lat[0]
            }
            this.setLngLat([lng, lat])
        }
        m.getPosition = function () {
            const lngLat = this.getLngLat().toArray()
            return lngLat.reverse()
        }
        m.getLatitude = function () {
            const latLng = this.getPosition()
            return latLng[0]
        }
        m.getLongitude = function () {
            const latLng = this.getPosition()
            return latLng[1]
        }
        m.setLatLng = function () {
            this.setLngLat.apply(this, arguments.reverse())
        }

        // Marker specific click
        if (markerOptions.click) {
            el.addEventListener('click', () => markerOptions.click.bind(m))
        }
        // Click to apply to all markers
        if (this._options.markerClick) {
            el.addEventListener('click', (e) => {
                return this._options.markerClick.bind(m)(e)
            })
        }
        // Active click
        el.addEventListener('click', () => {
            this.unsetActiveMarkers()
            m.setActive(true)
        })
        if (this._options.markerEvents) {
            for (const evt in this._options.markerEvents) {
                el.addEventListener(evt, this._options.markerEvents[evt].bind(m))
            }
        }
        if (markerOptions.events) {
            for (const evt in markerOptions.events) {
                el.addEventListener(evt, markerOptions.events[evt].bind(m))
            }
        }

        this.markers.push(m)

        return m
    }

    on() {
        return this.map.on.apply(this.map, arguments)
    }

    panTo(lat, lng, options = {}) {
        let center = null
        if (arguments.length === 1) {
            if (Array.isArray(lat)) {
                center = [lat[1], lat[0]]
            } else if (lat instanceof this.mapboxgl.Marker) {
                center = lat.getLngLat()
            }
        } else {
            center = [lng, lat]
        }

        this.map.flyTo({
            center: center,
            ...options,
        })
    }

    setPadding(padding) {
        return this.map.easeTo({
            padding,
            duration: 500,
        })
    }

    runQueue() {
        while (this.loadQueue.length) {
            const queueItem = this.loadQueue.shift()
            queueItem.apply(this, [])
        }
    }

    removeShapes(condition) {
        for (let i = this.overlays.length - 1; i >= 0; i--) {
            if (condition) {
                const conditionResult = condition.apply(this, [this.overlays[i], 'shape'])
                if (conditionResult === false) {
                    continue
                }
            }
            this.map.removeLayer(this.overlays[i].layer.id)
            if (
                this.overlays[i].layer.source &&
                typeof this.overlays[i].layer.source === 'string'
            ) {
                this.map.removeSource(this.overlays[i].layer.source)
            }
            this.overlays.splice(i, 1)
        }
    }

    setCenter(lat, lng) {
        if (Array.isArray(lat)) {
            lng = lat[1]
            lat = lat[0]
        } else if (typeof lat === 'object') {
            lng = lat.lng
            lat = lat.lat
        }
        return this.map.setCenter([lng, lat])
    }

    renderCircleOverlay(lat, lng, radius) {
        const coordinates = this.getCirclePoints(lat, lng, radius)

        const data = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [coordinates],
            },
        }

        const source = { type: 'geojson', data: data }

        const layer = {
            type: 'fill',
            layout: {},
            paint: {
                'fill-color': 'rgba(252,157,78,0.2)',
                'fill-outline-color': 'rgb(255, 132, 30)',
            },
        }

        const layerObj = this.addLayer(layer, source)

        layerObj.radius = radius
        layerObj.lat = lat
        layerObj.lng = lng

        layerObj.getCenter = function () {
            return [lat, lng]
        }
        layerObj.setRadius = function (radius) {
            return this.update(this.lat, this.lng, radius)
        }
        layerObj.setCenter = function (lat, lng) {
            if (Array.isArray(lat)) {
                lng = lat[1]
                lat = lat[0]
            }
            return this.update(lat, lng, this.radius)
        }
        layerObj.remove = () => {
            return this.removeShape(layerObj)
        }
        layerObj.update = (lat, lng, radius) => {
            if (!this.loaded) {
                return
            }
            const coordinates = this.getCirclePoints(lat, lng, radius)
            layerObj.updateData({
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [coordinates],
                },
            })
            layerObj.lat = lat
            layerObj.lng = lng
            layerObj.radius = radius
        }
        return layerObj
    }

    setZoomFromPositions(fitOptions = {}) {
        if (!this.isLoaded()) {
            this.queue(function () {
                this.setZoomFromPositions()
            })
        }
        let bounds = null
        if (this.markers.length) {
            bounds = this.markers.reduce(function (acc, marker) {
                const markerBounds = marker.getLngLat().toBounds(100)
                return acc === null ? markerBounds : acc.extend(markerBounds)
            }, null)
        } else if (this.overlays.length) {
            bounds = this.overlays
                .filter((overlay) => {
                    return overlay.source?.data?.geometry?.type === 'Polygon'
                })
                .reduce((acc, polygon) => {
                    let bounds = acc === null ? null : acc
                    polygon.source.data.geometry.coordinates.forEach((coords) => {
                        coords.forEach((lngLat) => {
                            const ll = new mapboxgl.LngLat(lngLat[0], lngLat[1]).toBounds(100)
                            bounds = bounds === null ? ll : bounds.extend(ll)
                        })
                    })
                    return bounds
                }, null)
        }
        if (!bounds) {
            return
        }
        this.log('Fitting bounds.')

        this.setCenter(bounds.getCenter())

        fitOptions = {
            ...(this._options.fitOptions || {}),
            ...fitOptions,
        }
        this.map.fitBounds(bounds, {
            linear: false,
            duration: 1000,
            ...fitOptions,
        })
    }

    setBearing(bearing) {
        return this.map.setBearing(bearing)
    }

    renderMarkers(markers, autoZoom) {
        // Reverse order to preserve z-index
        // const bounds = new this.mapboxgl.LngLatBounds()
        if (markers && markers.length) {
            for (let i = markers.length - 1; i >= 0; i--) {
                const m = markers[i]
                this.renderMarker(m)
            }
        }
        autoZoom = autoZoom == null ? true : autoZoom
        if (autoZoom) {
            this.setZoomFromPositions()
        }
    }

    panToWithOffset(lat, lng, offsetX, offsetY, options = {}) {
        let center = null
        if (arguments.length === 3) {
            if (Array.isArray(lat)) {
                center = [lat[1], lat[0]]
            } else if (lat instanceof this.mapboxgl.Marker) {
                center = lat.getLngLat()
            }
            offsetY = offsetX
            offsetX = lng
        } else {
            center = [lng, lat]
        }

        this.map.flyTo({
            center: center,
            offset: [offsetX, offsetY],
            ...options,
        })
    }

    removeShape(shape) {
        try {
            this.map.removeLayer(shape.layer.id)
        } catch (e) {
            /* empty */
        }
        const index = this.overlays.indexOf(shape)
        if (index > -1) {
            this.overlays.splice(index, 1)
        }
    }

    hideControl(key) {
        if (typeof key === 'object') {
            key = key.key
        }
        const control = this.controls[key]
        if (control && control.visible) {
            this.map.removeControl(control.control)
            this.controls[key].visible = false
        }
    }

    getViewport() {
        const bounds = this.map.getBounds()
        const nw = bounds.getNorthWest()
        const se = bounds.getSouthEast()
        return [
            [nw.lat, nw.lng],
            [se.lat, nw.lng],
            [se.lat, se.lng],
            [nw.lat, se.lng],
        ]
    }

    addLayer(layer, source) {
        if (!layer.id) {
            layer.id = 'overlays-' + this.overlayIndex
        }

        if (source) {
            const sourceId = layer.id + '-source'
            layer.source = sourceId
        }

        const layerObj = {
            layer: layer,
            source: source,
            map: this.map,

            updateData: function (data) {
                if (this.layer.source) {
                    this.source.data = data
                    this.map.getSource(this.layer.source).setData(this.source.data)
                }
            },

            loaded: false,

            load: function () {
                if (this.source && this.layer.source) {
                    this.map.addSource(this.layer.source, this.source)
                }
                this.map.addLayer(this.layer)
                this.loaded = true
            },
        }

        this.overlays.push(layerObj)
        this.overlayIndex++

        if (!this.isLoaded()) {
            this.queue(function () {
                layerObj.load()
            })
        } else {
            layerObj.load()
        }

        return layerObj
    }

    unsetActiveMarkers() {
        this.markers.forEach(function (marker) {
            marker.setActive(false)
        })
    }

    resize() {
        return this.map.resize()
    }

    setPitch(pitch) {
        return this.map.setPitch(pitch)
    }

    showControl(key) {
        if (typeof key === 'object') {
            key = key.key
        }
        const control = this.controls[key]
        if (control && !control.visible) {
            this.map.addControl(control.control, control.position)
            this.controls[key].visible = true
        }
    }

    queue(queue) {
        this.loadQueue.push(queue)
    }
}
