import { requireOne } from '../../utils/require.js'

let scriptLoaded = false

export const googleMaps = () => {
    if (scriptLoaded && window.google.maps) {
        return Promise.resolve(window.google.maps)
    }

    const lang = document.documentElement.lang
    const libs = `places,visualization`
    const apiKey = `AIzaSyDYCS_YIPeZrJgruQwzS8c4D2aW7BsWKOI`

    const url = `//maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libs}&language=${lang}&callback=initGoogleMaps`

    return new Promise((resolve, reject) => {
        window.initGoogleMaps = () => {
            scriptLoaded = true
            resolve(window.google.maps)
        }

        requireOne(url, null, reject)
    })
}
