/**
 * @TODO: Rewrite to a promise or _deprecate_ in favor of dynamic imports
 */
export const requireOne = function (url, load, error) {
    if (typeof url !== 'string') {
        return requireMany(url, load, error)
    }

    let d = document
    let t = 'script'
    let o = d.createElement(t)
    let s = d.getElementsByTagName(t)[0]

    o.src = url

    if (load) {
        o.addEventListener(
            'load',
            function (e) {
                load(null, e)
            },
            false,
        )
    }

    if (error) {
        o.addEventListener(
            'error',
            function (e) {
                error(null, e)
            },
            false,
        )
    }
    s.parentNode.insertBefore(o, s)
}

export const requireMany = function (u, c, ec) {
    let script = u.shift()
    requireOne(
        script,
        (a, e) => {
            if (u.length) {
                requireMany(u, c, ec)
            } else {
                c(null, e)
            }
        },
        ec,
    )
}
